import React from 'react';
import Breadcrumbs from "../breadcrumbs";

import Formulario from '../Contact'

import { graphql, useStaticQuery } from 'gatsby';
import BannerTop from '../Global/Modules/MediaPush/BannerTop';

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              keys {
                captcha
              }
              social{
                facebook
                instagram
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                    }
                    footer {
                        rows {
                            title_contact
                            button_contact {
                                value
                                link
                            }
                            title_office
                            title_social
                            title_newsletter
                        }
                    }
              }
          }
    }`)

    return(
        <section className="contact-main">
            <BannerTop page="contacto"/>
            <div className="container-fluid">
                <div className="row height">
                    <div className="col-12 d-flex align-items-center">
                        <div className="row mt-4 pt-lg-0 pt-5 content-main">
                            <div className="col-12">
                                <Breadcrumbs
                                    props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: "Contacto", route:   "", location: ""},
                                    ]}
                                />
                            </div>
                            <div className="col-12">
                                <h1>CONTACTO</h1>
                            </div>
                            <div className="col-lg-7 order-2 order-lg-1">
                                <Formulario from={'contact'} />
                            </div>
                            <div className="col-lg-3 offset-lg-1 text-center contact-nav d-lg-flex d-none align-items-center order-1 order-lg-2">
                                <div className="center-contact">
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.rows.title_contact}</h5>
                                        <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                                    </div>
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[0].name}</h5>
                                        <p >{realEstate.branch_office[0].address}</p>
                                        <p >{realEstate.branch_office[0].city}</p>
                                        <p >{realEstate.branch_office[0].region}</p>
                                        <p >Teléfono {realEstate.branch_office[0].contact.phone}</p>
                                        <p >Whatsapp {realEstate.branch_office[0].contact.whatsapp}</p>
                                        <p >Matrícula {realEstate.branch_office[0].broker_id}</p>

                                    </div>
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[1].name}</h5>
                                        <p >{realEstate.branch_office[1].address}</p>
                                        <p >{realEstate.branch_office[1].city}</p>
                                        <p >{realEstate.branch_office[1].region}</p>
                                        <p >Teléfono {realEstate.branch_office[1].contact.phone}</p>
                                        <p >Whatsapp {realEstate.branch_office[1].contact.whatsapp}</p>
                                        <p >Matrícula {realEstate.branch_office[1].broker_id}</p>
                                    </div>
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.rows.title_social}</h5> 
                                        <a target="_blank" href={realEstate.social.instagram} class="d-inline"><i class="icon-instagram1"></i></a>
                                        <a target="_blank" href={realEstate.social.facebook} class="d-inline"><i class="icon-facebook1"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default Main;